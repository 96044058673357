import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@organisms/layout'
import Heading from '@particles/heading'
import Text from '@particles/text'
import SEO from '@atoms/seo'
import { Container } from '@organisms/container'

const MissionPage = ({ location }) => (
  <Layout location={location}>
    <Container>
      <SEO title="Our mission" location={location} />
      <Heading h1 margin="T4 B1.5">
        Our Vision and Mission
      </Heading>
      <Heading h3 margin="T2.5 B1.5">
        Vision
      </Heading>
      <Text size={1.25}>
        Be the world&apos;s leading open idea-exchange platform for the
        discovery and standardization of business metrics.
      </Text>
      <Heading h3 margin="T2.5 B1.5">
        Mission
      </Heading>
      <Text size={1.25}>
        To inspire a culture of data-driven leadership based on data trust by
        providing the most complete, high quality dictionary for metric
        definitions, formulas, and benchmarks, curated and supported by industry
        experts.
      </Text>
      <Heading h3 margin="T2.5 B1.5">
        Guiding Principles
      </Heading>
      <Text size={1.25}>
        We value quality and timeliness of metric definitions
        <br />
        We strive to include benchmarks wherever possible (on ratios and rates)
        <br />
        We are opinionated about metric definitions and calculations
        <br />
        We respect each other&apos;s domain expertise
        <br />
        No one metric is owned by a contributor. A metric can have multiple
        contributors.
        <br />
        We collaborate in a vendor agnostic way (Klipfolio, dbt Labs, others…)
        <br />
      </Text>
    </Container>
  </Layout>
)

MissionPage.propTypes = {
  location: PropTypes.any
}

export default MissionPage
